//
//
//
//
//
//
//
//
//
//


export default{

    name: "feefocarousel"

}

